import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { isFound } from "../utilities/isFound";

const initialState = {
  archivedMessagesStored: [],
  archivedMessages: [],
  archivedMessagesStatus: "idle",
  pageCounter: 1,
  hasNextPageForArchivedMessages: null,
  error: null,
};

export const getArchivedMessages = createAsyncThunk(
  "archived/getArchivedMessages",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body.websiteID}/archivedMessages/conversations/${body.conversationID}?limit=15&page=${body?.page}`
      );
      data = response.data?.data?.docs;
      if (response.status === 200) {
        return {
          data,
          hasNextPage: response.data.data?.hasNextPage,
          page: body?.page,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "archivedMessages",
  initialState,
  reducers: {
    storeArchivedMessages: (state, action) => {
      state.archivedMessages.forEach((item) =>
        item._id === action.payload
          ? (() => {
              state.archivedMessagesStored = item.archivedMessages;
              state.hasNextPageForArchivedMessages = item?.hasNextPage;
              state.pageCounter = item?.page;
            })()
          : null
      );
    },

    removeRelatedArchivedMessages: (state, action) => {
      state.archivedMessagesStored = [];
      state.archivedMessages = state.archivedMessages.filter(
        (item) => item._id !== action.payload
      );
    },
  },
  extraReducers: {
    [getArchivedMessages.pending]: (state, action) => {
      action.meta.arg.page === 1 && (state.archivedMessagesStatus = "loading");
    },
    [getArchivedMessages.fulfilled]: (state, action) => {
      state.archivedMessagesStatus = "succeeded";
      state.pageCounter = action.payload?.page + 1;
      state.hasNextPageForArchivedMessages = action.payload?.hasNextPage;
      isFound(
        state?.archivedMessages,
        action?.payload?.data[0]?.conversation
      ) === false
        ? (state.archivedMessages = [
            ...state.archivedMessages,
            {
              _id: action.payload?.data[0].conversation,
              archivedMessages: action.payload?.data,
              page: action.payload?.page + 1,
              hasNextPage: action.payload?.hasNextPage,
            },
          ])
        : (state.archivedMessages = state.archivedMessages?.map((item) =>
            item?._id === action.payload?.data[0].conversation
              ? {
                  ...item,
                  archivedMessages: action.payload?.data
                    ?.concat(item?.archivedMessages)
                    ?.reduce((acc, current) => {
                      const x = acc.find((item) => item._id === current._id);

                      if (!x) {
                        return acc.concat([current]);
                      } else {
                        return acc;
                      }
                    }, []),
                  page: action.payload?.page + 1,
                  hasNextPage: action.payload?.hasNextPage,
                }
              : item
          ));
      state.archivedMessages.forEach((item) =>
        item._id === action.payload?.data[0].conversation
          ? (state.archivedMessagesStored = item.archivedMessages)
          : null
      );
    },
    [getArchivedMessages.rejected]: (state) => {
      state.archivedMessagesStatus = "fail";
    },
  },
});
export const { storeArchivedMessages, removeRelatedArchivedMessages } =
  slice.actions;
export const reducer = slice.reducer;
export default slice;
